<template>
  <div class="w-100 h-100 position-relative">
    <div
      class="custom-container d-flex flex-column justify-content-center align-items-center"
      v-if="stayType==0"
    >
      <!-- <div
        class="custom-height w-50  p-2 d-flex justify-content-start align-items-start flex-column border-right"
      >
        <div class="font-large color-black font-butler mb-1">
          Availability
        </div>
        <p class="font-regular">When is the space available?</p>
        <div class="w-100 mb-2">
          <p class="color-black">How far in advance can guest book?</p>
          <vSelect
            :options="advanceGuestBooking"
            id="custom-select"
            v-model="advanceGuestBookingSelected"
          ></vSelect>
        </div>
        <div class="w-100 mb-2">
          <p class="color-black">
            How much notice do you need before the guests arrive?
          </p>
          <vSelect
            :options="noticeBeforeGuestArrives"
            id="custom-select-1"
            v-model="noticeBeforeGuestArrivesSelected"
          ></vSelect>
        </div>
        <div class="w-100">
          <p class="color-black">
            How long can guests stay?
          </p>
          <div class="d-flex justify-content-center">
            <div class="w-50 d-flex flex-column pr-2">
              <p class="font-small color-black">Earliest Move in Date</p>
              <DatePick
                v-model="earliestMoveInDate"
                :displayFormat="'DD.MM.YYYY'"
                id="vue-datepicker-2"
                :isDateDisabled="isFutureDate"
              ></DatePick>
            </div>
            <div class="w-50 d-flex flex-column pr-2">
              <p class="font-small color-black">Duration of stay</p>
              <vSelect
                :options="durationOfStay"
                id="custom-select-2"
                v-model="durationOfStaySelected"
              ></vSelect>
            </div>
          </div>
          <div class="btn bg-color-black color-white mt-2">
            Set a min and max duration
          </div>
        </div>
      </div>
      <div
        class="custom-height w-50 p-2 d-flex justify-content-start align-items-start flex-column "
      >
        <div class="font-large color-black font-butler mb-1">
          Pricing
        </div>
        <p class="font-regular">How do you want to price this unit?</p>
        <div class="d-flex justify-content-center w-100 mb-2">
          <div class="w-50 d-flex flex-column pr-2">
            <p class="font-small color-black">Base price</p>
            <input
              type="text"
              placeholder="$0"
              style="border:1px solid rgba(1,1,1,.2); border-radius:5px; height:2.35rem; outline:none;"
              v-model="basePrice"
            />
          </div>
          <div class="w-50 d-flex flex-column pr-2">
            <p class="font-small color-black">Currency</p>
            <vSelect
              :options="currency"
              id="custom-select-3"
              v-model="currencySelected"
            ></vSelect>
          </div>
        </div>
        <div class="w-100">
          <p class="font-regular">Security deposit</p>
          <input
            class="w-100"
            type="text"
            style="border:1px solid rgba(1,1,1,.2); border-radius:5px; height:2.35rem; outline:none;"
            placeholder="$0"
            v-model="securityDeposit"
          />
        </div>
        <div class="d-flex mt-3">
          <div class="color-black font-regular mr-3">
            Total Estimated Rent Price
          </div>
          <div class="font-weight-600 color-black font-regular">
            ${{ estimatedPrice }}
          </div>
        </div>
      </div> -->
        <div class="font-large color-black font-butler mb-1">
          Pricing Details
        </div>
        <div>How do you want to price this unit?</div>
        <div class="short-stay" @click="changeStayType(1)" v-if="furnished == 1" disabled>
          Holiday Living
        </div>
        <div v-if="pricingDone.shortStay">
            <div class="round">
    <input type="checkbox" v-model="pricingDone.shortStay" id="checkbox" />
    <label for="checkbox"></label>
  </div>
          </div>
        <div class="long-stay" @click="changeStayType(2)">
          Urban Living
        </div>
        <div v-if="pricingDone.longStay">
            <div class="round">
    <input type="checkbox" v-model="pricingDone.longStay" id="checkbox2" />
    <label for="checkbox2"></label>
  </div>
          </div>
    </div>
    <!-- ------------------------------------------------------------------------------------------------------------------------ -->
    <div
      class="custom-container d-flex flex-column justify-content-center align-items-center"
      v-if="stayType==1"
    >
    <div class="font-large color-black font-butler mb-1">
          Set Pricing for Short Stay.
        </div>
        <div class="w-50">
          <p class="font-regular">Set Weekday base price.</p>
          <input
            class="w-100"
            type="number"
            style="border:1px solid rgba(1,1,1,.2); border-radius:5px; height:2.35rem; outline:none;"
            placeholder="₹ 0"
            v-model="shortStayData.weekDayBasePrice"
          />
          <p class="font-small color-black">The rates are set per night.</p>
        </div>
        
        <div class="w-50">
          <p class="font-regular">Set Weekend Base Price</p>
          <input
            class="w-100"
            type="number"
            style="border:1px solid rgba(1,1,1,.2); border-radius:5px; height:2.35rem; outline:none;"
            placeholder="₹ 0"
            v-model="shortStayData.weekEndBasePrice"
          />
          <p class="font-small color-black">The rates are set per night for weekends ( sat, sun )..</p>
        </div>

        <div class="w-50">
          <p class="font-regular">Set Weekly Discounts</p>
          <input
            class="w-100"
            type="number"
            style="border:1px solid rgba(1,1,1,.2); border-radius:5px; height:2.35rem; outline:none;"
            placeholder="Enter in %"
            v-model="shortStayData.weeklyDiscounts"
          />
          <p class="font-small color-black">You can specify how much of a weekly discount you want to offer.</p>
        </div>

        <div class="w-50">
          <p class="font-regular">Set Monthly Discounts</p>
          <input
            class="w-100"
            type="number"
            style="border:1px solid rgba(1,1,1,.2); border-radius:5px; height:2.35rem; outline:none;"
            placeholder="Enter in %"
            v-model="shortStayData.monthlyDiscounts"
          />
          <p class="font-small color-black">You can specify how much of a monthly discount you want to offer.</p>
        </div>
        <div class="w-50">
          <p class="font-regular">Cancellation charge</p>
          <input
            class="w-100"
            type="number"
            style="border:1px solid rgba(1,1,1,.2); border-radius:5px; height:2.35rem; outline:none;"
            placeholder="Enter in %"
            v-model="shortStayData.cancellationCharge"
          />
          <p class="font-small color-black">You can specify how much of a monthly discount you want to offer.</p>
        </div>

        <div class="custom-container d-flex justify-content-center">
          <div class="btn bg-color-white color-blue mr-2" style="border: 1px solid rgb(13, 110, 253);" @click="cancleShortStay">Cancel</div>
          <div class="btn color-white bg-color-blue rounded" @click="saveShortStay"> Save</div>
        </div>

    </div>
    <!-- ------------------------------------------------------------------------------------------------------------------------ -->
    <div
      class="custom-container d-flex flex-column justify-content-center align-items-center"
      v-if="stayType==2"
    >
    <div class="font-large color-black font-butler mb-1">
          Set Pricing for Extended Stay.
        </div>
        
        <div class="w-50">
          <p class="font-regular">Set Price</p>
          <input
            class="w-100"
            type="number"
            style="border:1px solid rgba(1,1,1,.2); border-radius:5px; height:2.35rem; outline:none;"
            placeholder="₹ 0"
            v-model="longStayData.price"
          />
          <p class="font-small color-black">The rates are set per month.</p>
        </div>

        <div class="w-50">
          <p class="font-regular">Set Deposit</p>
          <input
            class="w-100"
            type="number"
            style="border:1px solid rgba(1,1,1,.2); border-radius:5px; height:2.35rem; outline:none;"
            placeholder="₹ 0"
            v-model="longStayData.deposit"
          />
          <p class="font-small color-black">Choose a deposit amount.</p>
        </div>

        <div class="w-50">
          <p class="font-regular">How many days' notice do you require before a guest departs?</p>
          <input
            class="w-100"
            type="number"
            style="border:1px solid rgba(1,1,1,.2); border-radius:5px; height:2.35rem; outline:none;"
            placeholder=""
            v-model="longStayData.noticePeriod"
          />
          <p class="font-small color-black"></p>
        </div>
        <div class="w-50">
          <p class="font-regular">Set Cancellation charges</p>
          <input
            class="w-100"
            type="number"
            style="border:1px solid rgba(1,1,1,.2); border-radius:5px; height:2.35rem; outline:none;"
            placeholder="Enter in %"
            v-model="longStayData.cancellationCharge"
          />
        </div>
        <p></p>

        <div class="custom-container d-flex justify-content-center">
          <div class="btn bg-color-white color-blue mr-2" style="border: 1px solid rgb(13, 110, 253);" @click="cancleLongStay">Cancel</div>
          <div class="btn color-white bg-color-blue rounded" @click="saveLongStay"> Save</div>
        </div>

    </div>


  </div>
</template>

<script>
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import DatePick from "vue-date-pick";
import Calendar from "@/views/components/calendar/CalendarDisableDate.vue";
import "vue-date-pick/dist/vueDatePick.css";
import "vue-select/dist/vue-select.css";
export default {
  data() {
    return {
      

      stayType: 0,

      // shortStayData:{
      //   weekEndBasePrice:null,
      //   weekDayBasePrice:null,
      //   monthlyDiscounts:null,
      //   weeklyDiscounts:null,
      //   cancelStatus:"no",
      //   cancellationCharge:null,
      // },

      // longStayData: {
      //   price:null,
      //   cancelStatus:"no",
      //   cancellationCharge:null,
      //   deposit:null,
      //   noticePeriod:null,

      // },



      advanceGuestBookingSelected: "",
      noticeBeforeGuestArrivesSelected: "",
      durationOfStaySelected: "",
      currencySelected: "",
      earliestMoveInDate: "",
      basePrice: 0,
      securityDeposit: 0,
      estimatedPrice: 0,

      advanceGuestBooking: ["Anytime", "1 Year", "2 Years and More"],
      noticeBeforeGuestArrives: [
        "1 Day",
        "2 Days",
        "3 Days",
        "4 Days",
        "5 Days",
        "A Week",
        "A Month",
      ],
      durationOfStay: ["Nightly", "Monthly", "Long Term"],
      currency: ["USD", "INR", "PKR", "BDT"],

      date: "",
      totalPrice: "3500",
    };
  },
  methods: {
    // isFutureDate(date) {
    //   const currentDate = new Date();
    //   return date < currentDate;
    // },

    validateShort(){
      if(this.shortStayData.weeklyDiscounts && this.shortStayData.weekEndBasePrice && this.shortStayData.weekDayBasePrice && this.shortStayData.monthlyDiscounts && this.shortStayData.cancellationCharge){
        return true
      }
      this.$toasted.error("Please fill all the fields", {
          position: "top-center",
          action: {
            text: "Cancel",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        });
      return false
    },
    validateLong(){
      if(this.longStayData.noticePeriod && this.longStayData.deposit && this.longStayData.price && this.longStayData.cancellationCharge){
        return true
      }
      this.$toasted.error("Please fill all the fields", {
          position: "top-center",
          action: {
            text: "Cancel",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        });
      return false
    },

    cancleShortStay() {
      // this.shortStayData = {
      //   weekEndBasePrice:null,
      //   weekDayBasePrice:null,
      //   monthlyDiscounts:null,
      //   weeklyDiscounts:null,
      //   cancelStatus:"no",
      //   cancellationCharge:null,
      // }
      let pricingDone = {
        ...this.pricingDone,
        shortStay: false
      }
            this.setPricingDone(pricingDone)

      this.changeStayType(0)
    },

    saveShortStay() {
      if(!this.validateShort()){
        return;
      }
      let pricingDone = {
        ...this.pricingDone,
        shortStay: true
      }
      this.setPricingDone(pricingDone)
      this.changeStayType(0)

    },

    cancleLongStay() {
      // this.longStayData = {
      //   price:null,
      //   deposit:null,
      //   noticePeriod:null,
      //   cancelStatus:"no",
      //   cancellationCharge:null,
      // }
      let pricingDone = {
        ...this.pricingDone,
        longStay: false
      }
            this.setPricingDone(pricingDone)

      this.changeStayType(0)
    },

    saveLongStay() {
      if(!this.validateLong()){
        return;
      }
      let pricingDone = {
        ...this.pricingDone,
        longStay: true
      }
      this.setPricingDone(pricingDone)
      this.changeStayType(0)

    },

    changeStayType(val) {
      this.stayType = parseInt(val);
    },

    // useadvanceGuestBooking() {
    //   this.advanceGuestBookingSelected = this.advanceGuestBooking[0];
    // },
    // useNoticeBeforeGuestArrives() {
    //   this.noticeBeforeGuestArrivesSelected = this.noticeBeforeGuestArrives[0];
    // },
    // useDurationOfStay() {
    //   this.durationOfStaySelected = this.durationOfStay[0];
    // },
    // useCurrency() {
    //   this.currencySelected = this.currency[0];
    // },

  },

  mounted() {
    // this.useadvanceGuestBooking();
    // this.useNoticeBeforeGuestArrives();
    // this.useDurationOfStay();
    // this.useCurrency();
    console.log(this.shortStayData)
  },

  watch: {

    shortStayData: {
      handler: function() {

        console.log("kaam kar rha hai")
        // Log it
        this.setShortStayData(this.shortStayData)
      },
      deep: true,
    },
    longStayData: {
      handler: function() {

        console.log("kaam kar rha ye v hai")
        // Log it
        this.setLongStayData(this.longStayData)
      },
      deep: true,
    },

    // advanceGuestBookingSelected: function() {
    //   console.log(this.advanceGuestBookingSelected);
    //   this.setadvanceGuestBooking(this.advanceGuestBookingSelected);
    // },
    // noticeBeforeGuestArrivesSelected: function() {
    //   console.log(this.noticeBeforeGuestArrivesSelected);
    //   this.setNoticeBeforeGuestArrives(this.noticeBeforeGuestArrivesSelected);
    // },

    // durationOfStaySelected: function() {
    //   console.log(this.durationOfStaySelected);
    //   this.setDurationOfStay(this.durationOfStaySelected);
    // },
    // currencySelected: function() {
    //   console.log(this.currencySelected);
    //   this.setCurrency(this.currencySelected);
    // },
    // basePrice: function() {
    //   console.log(this.basePrice);     
    //   this.setBasePrice(this.basePrice);
    //   this.estimatedPrice = parseInt(this.securityDeposit) + parseInt(this.basePrice)
    // },
    // earliestMoveInDate: function() {
    //   console.log(this.earliestMoveInDate);
    //   this.setEarliestMoveInDate(this.earliestMoveInDate);
    // },
    // securityDeposit: function() {
    //   console.log(this.securityDeposit);
    //   this.setSecurityDeposit(this.securityDeposit);
    //   this.estimatedPrice = parseInt(this.securityDeposit) + parseInt(this.basePrice)


    // },
    // estimatedPrice: function() {
    //   console.log(this.estimatedPrice);
    //   this.setEstimatedPrice(this.estimatedPrice);
    // },
  },

  props: [
    // "setadvanceGuestBooking",
    // "setNoticeBeforeGuestArrives",
    // "setDurationOfStay",
    // "setCurrency",
    // "setBasePrice",
    // "setEarliestMoveInDate",
    // "setSecurityDeposit",
    // "setEstimatedPrice",
    "pricingDone",
    "setShortStayData",
    "setLongStayData",
    "setPricingDone",
    "longStayData",
    "shortStayData",
    "furnished",
  ],

  components: {
    vSelect,
    Datepicker,
    DatePick,
    Calendar,
  },
  computed: {},
};
</script>

<style lang="scss" scoped>

.short-stay{
  background-color: #00beff;
  color: white;
    width: 30rem;
    height: 11rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:3rem;
    font-size: 1.5rem;
}
.long-stay{
  background-color: #012c64;
  color: white;
    width: 30rem;
    height: 11rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem 0;
    font-size: 1.5rem;
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}














/* general styling */
html, body {
  height: 100%;
  margin: 0;
}

body {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f1f2f3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.container {
  margin: 0 auto;
}



.custom-height {
  height: 30rem;
}
#custom-select {
  width: 100%;
  height: 2.5rem !important;
}
#vue-datepicker {
  width: 20rem !important;
}
#custom-select-1 {
  width: 100%;
  height: 2.5rem !important;
}
#custom-select-2 {
  width: 100%;
  height: 2.5rem !important;
}
#custom-select-3 {
  width: 100%;
  height: 3rem !important;
}
@media only screen and(max-width:600px) {
  .custom-container {
    display: flex;
    flex-direction: column !important;
    align-items: start;
  }
  .custom-height {
    width: 100% !important;
    min-height: 100%;
    margin-bottom: 1rem;
    &:nth-child(1) {
      margin-bottom: 4rem;
    }
  }
}
</style>
