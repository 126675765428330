<template>
  <div class="w-100 h-100">
    <div
      class="d-flex flex-column justify-content-between  color-black font-butler font-large mb-2"
    >
      Hii Owner,
      <br />
      Please add your property
    </div>
    <div class="font-regular mb-2">
      First we need a few details about the unit
    </div>
    <div class="custom-input-group d-flex justify-content-between mb-2">
      <div class="custom-input-pair d-flex flex-column">
        <label for="">Property Name</label>
        <input
          type="text"
          class="custom-input-large"
          placeholder="Property Name"
          v-model="houseName"
        />
      </div>
      <!-- <div class="custom-input-pair d-flex flex-column">
        <label for="">Contact No.</label>
        <input
          type="text"
          class="custom-input-large"
          placeholder="Contact No."
          v-model="contactNo"
        />
      </div> -->
      
    </div>
    <div class="custom-input-group d-flex justify-content-between mb-2">
      <div class="custom-input-pair d-flex flex-column">
        <label for="">Unit No.</label>
        <input
          type="text"
          class="custom-input-large"
          placeholder="Apt no"
          v-model="houseNo"
        />
      </div>
      <div class="custom-input-pair d-flex flex-column">
        <label for="">Locality / Street Name</label>
        <input
          type="text"
          class="custom-input-large"
          placeholder="Address"
          v-model="addressLine1"
        />
      </div>
    </div>
    <div class="custom-input-group d-flex justify-content-between mb-2">
      <div class="custom-input-pair d-flex flex-column">
        <!-- <label for="">City</label>
        <input
          type="text"
          class="custom-input-large"
          placeholder="City"
          v-model="city"
          @change="setCity($event.target.value)"
        /> -->
        <label for="">City</label>
        <b-form-group>
        <v-select
          class="custom-input-large"
          v-model="selectedCity"
          label="title"
          :options="cityOptions"
        />
      </b-form-group>
      </div>
      <div class="custom-input-pair d-flex flex-column">
        <label for="">State</label>
        <input
          type="text"
          class="custom-input-large"
          placeholder="State"
          v-model="state"
           @change="setState($event.target.value)"
        />
      </div>
      
    </div>
    <div class="custom-input-group d-flex justify-content-between mb-2">
      <div class="custom-input-pair d-flex flex-column">
        <label for="">Country</label>
        <input
          type="text"
          class="custom-input-large"
          placeholder="Country"
          v-model="country"
           @change="setCountry($event.target.value)"
        />
      </div>
      <div class="custom-input-pair d-flex flex-column">
        <label for="">Zip Code</label>
        <input
          type="text"
          class="custom-input-large"
          placeholder="Zip Code"
          v-model="pincode"
        />
      </div>
      
    </div>
    <div class="custom-input-group d-flex justify-content-between mb-2">
      <div class="custom-input-pair d-flex flex-column">
        <label for="">Description</label>
        <textarea
          placeholder="Description"
          v-model="description"
          rows="4" cols="50"
          style="outline:none;border:none;resize:none;background-color:transparent;border:1px solid #ccc;padding:10px;"
        >
        </textarea>
      </div>
      
      
    </div>
    

  </div>
</template>

<script>
import { BRow, BCol, BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select'
export default {
  data() {
    return {
      // contactNo: "",
      // // houseNo : '',
      // houseName: '',
      // latitude : 0,
      // longitude : 0,
      // pincode: 0,
      // description: '',
      // addressLine1 : '',
      // addressLine2 : '',
      // listingType : '',
      // personName: "Mahipal",
      // isPrivate: "",
      cityOptions:[
        { title: 'Bangalore' }, 
        { title: 'Hosur' }, 
        { title: 'Chikmagalur' }, 
        { title: 'Lonavala' }, 
        { title:'Goa'}, 
        { title:'Bikaner'}, 
        { title:'Jodhpur'},
        ],
      // selectedCity: [{ title: 'Bangalore' }],
    };
  },

  methods: {





  },
  props: [
    "setHouseNo",
    "setHouseName",
    "setAddressLine1",
    "setAddressLine2",
    "mapAddress",
    "setContactNo",
    "setCountry",
    "setCity",
    "setState",
    "country",
    "city",
    "state",
    "setPincode",
    "setDescription",
    "houseNo",
    "houseName",
    "description",
    "houseNo",
    "contactNo",
    "latitude",
    "longitude",
    "pincode",
    "addressLine1",
    "addressLine2",
    "selectedCity"
  ],

  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect
  },


  watch :{
    houseNo : function(){
      this.setHouseNo(this.houseNo);
    },
    description : function(){
      this.setDescription(this.description);
    },
    houseName : function(){
      this.setHouseName(this.houseName);
    },
    pincode : function(){
      this.setPincode(this.pincode);
    },

    contactNo: function(){
      this.setContactNo(this.contactNo);
    },

    addressLine1 : function(){
      this.setAddressLine1(this.addressLine1);
    },
    addressLine2 : function(){
      this.setAddressLine2(this.addressLine2);
    },

    mounted(){
      console.log('Map Address:', this.mapAddress);
      document.getElementById('rev-geocode').innerHTML = this.mapAddress;
    }

    
    
    

  },
};
</script>

<style lang="scss" scoped>
.custom-input-group {
  .custom-input-pair {
    .custom-input-large {
      width: 17rem;
    }
    .custom-input-small {
      width: 7rem;
    }
    label {
      font-weight: 600;
      color: rgba($color: #000000, $alpha: 0.6);
    }
    input {
      padding: 0 0.5rem;
      border-radius: 0.3rem;
      border: 1.3px solid rgba($color: #000000, $alpha: 0.2);
      height: 2.3rem;
      background-color: transparent;
      transition: 0.3s ease;
      outline: none;
      &::placeholder {
        color: rgba($color: #000000, $alpha: 0.5);
      }
      &:focus {
        border: 1.3px solid rgba($color: #0d6efd, $alpha: 0.35);
        box-shadow: 0px 3px 10px -2px rgba($color: #0d6efd, $alpha: 0.15);
      }
      &:active {
        border: 1.3px solid rgba($color: #0d6efd, $alpha: 0.35);
        box-shadow: 0px 3px 10px -2px rgba($color: #0d6efd, $alpha: 0.15);
      }
    }
  }
  .custom-radio-group {
    input {
      height: 1.3rem;
      width: 1.3rem;
      margin-right: 0.3rem;
    }
    label {
      line-height: 0;
    }
  }
}
textarea {
    max-width:100%;
}
.custom-select-card {
  height: 4rem;
  width: 100%;
  border-radius: 0.5rem;
  padding: 0rem 1rem;
  border: 1.3px solid rgba($color: #000000, $alpha: 0.1);
  position: relative;
  img {
    opacity: 0.8;
    height: 35%;
  }

  &.active{
    border: 1px solid rgba($color: #0d6efd, $alpha: 1);
  }
}
@media only screen and (max-width: 550px) {
  .custom-input-group {
    display: flex;
    flex-direction: column;
    .custom-input-pair {
      margin-bottom: 1rem;
    }
  }
}
</style>
