<template>
  <div class="w-100 h-100">
    <div
      class="custom-container d-flex flex-column justify-content-center align-items-center"
    >
      <div
        class="cutsom-container-big d-flex justify-content-center align-items-center flex-column postion-relative"
        @dragenter="dragging = true"
        @dragend="dragging = false"
        @dragleave="dragging = false"
        @dragover="useOnDragOver($event)"
        @drop="useOnDrop($event)"
        :class="dragging ? 'drag-enter' : ''"
      >
        <img :src="imageFiles" alt="" />
        <div class="font-mid-large color-black font-butler">
          Add at least 4 photos
        </div>
        <div class="color-black text-center">
          <input
            id="article_file_input"
            type="file"
            name="article_picture"
            style="display: none;"
            @change="buttonFileAdd"
          />
          <a @click="openPopup" class="text-center">Upload from your device</a>
          or drag and drop in the box to upload
        </div>
      </div>
      <div class="custom-image-container-2 d-flex justify-content-center" v-if="imagesResult">
        <div class="custom-image-display" v-for="(item,index) in imagesResult" :key="index">
          <img :src="item.image_url" alt="">
          <div class="caption">
            <span>{{item.caption}}</span>
            <div style="cursor:pointer;" @click="deleteUploadedImage(item.id)">Delete</div>
          </div>
        </div>
      </div>
    </div>
    <div class="custom-popup-layout" v-if="popupOpened">
      <div class="custom-upload-popup p-2">
        <div style="margin-bottom:3rem;">Upload  your image below </div>
        <div class="custom-upload-box position-relative p-2 d-flex flex-column justify-content-center align-items-center">
          <div class="" v-if="!latestImage">
            <img :src="imageFiles" alt="" style="height:2.3rem;" />
          </div>
          <div class="custom-upload-text font-butler color-black" v-if="!latestImage">
            Upload image below
          </div>
          <div style="text-align:center;font-size:0.75rem;margin-top:10px;" v-if="!latestImage">
            Images must be a minimum of 500 x 500 pixels.
            File types must be JPG or PNG.
            Images should be compressed to be in the 100kb - 400kb range.
          </div>
          <div style="color:#0d6efd;cursor:pointer;margin-top:3rem;font-weight:bold;" @click="open_file" v-if="!latestImage">
            Select File
          </div>
          <div v-if="latestImage" >
            <img :src="latestImage" alt="" style="position:absolute;height:100%;width:100%;top:0;left:0;z-index:200;">
          </div>
        </div>
        <div>
          <label for=""><strong>Add Caption</strong></label>
          <input type="text" class="form-control" v-model="caption">
        </div>
        <div class="mt-1">
          <button type="button" class="custom-button" @click="cancelUpload">Cancel</button>
          <button type="button" class="custom-button-1" @click="uploadCaption">Upload</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imageFiles from "@/assets/icons/imageFiles.png";
export default {
  data() {
    return {
      imageFiles,
      dragging: false,
      file: "",
      files: [],
      justFiles: [],
      imageLink: [],
      popupOpened: false,
      caption: "",
      latestImage: null,
    };
  },
  methods: {
    //Open file by pressing button
    open_file() {
      document.getElementById("article_file_input").click();
    },
    useOnDragOver(e) {
      this.dragging = true;
      e.preventDefault();
      console.log("File is over drag area!");
    },

    // open file by drag and drop
    useOnDrop(e) {
      this.dragging = false;
      console.log("Enter useondrop", e);
      e.preventDefault();
      const drag = document.querySelector(".drag-enter");
      console.log("File dropped");
      const fileToBeEntered = e.dataTransfer.files[0];
      if (
        fileToBeEntered.type == "image/jpeg" ||
        fileToBeEntered.type == "image/jpg" ||
        fileToBeEntered.type == "image/png"
      ) {
        this.imageLink.push(fileToBeEntered);
        let fileUrl;
        const fileReader = new FileReader();
        fileReader.readAsDataURL(fileToBeEntered);
        fileReader.onload = () => {
          console.log("In Base 64");
          fileUrl = fileReader.result;
          if (!this.justFiles.includes(fileUrl)) {
            this.file = fileUrl;
            this.files.push({
              image: this.file,
              type: fileToBeEntered.type.split("/")[1],
            });
            this.justFiles.push(this.file);
          } else {
            alert("Same picture uploaded");
          }
        };
        fileReader.onerror = () => {
          console.log("error");
        };
      } else {
        alert("Only Image files accepted");
      }
    },

    // Upload caption

    uploadCaption() {
      // if(this.caption){
        this.files[this.files.length-1]["caption"] = this.caption;
        console.log(this.files)
        this.caption=null;
        this.latestImage = null;
        this.closePopup();
      // }else{
        // alert("Please add caption")
      // }
      this.useUploadImage();
    },

    //Add files by button
    buttonFileAdd(e) {
      console.log(e);
      const fileToBeEntered = e.target.files[0];
      if (
        fileToBeEntered.type == "image/jpeg" ||
        fileToBeEntered.type == "image/jpg" ||
        fileToBeEntered.type == "image/png"
      ) {
        this.imageLink.push(fileToBeEntered);
        let fileUrl;
        const fileReader = new FileReader();
        fileReader.readAsDataURL(fileToBeEntered);
        fileReader.onload = () => {
          console.log("In Base 64");
          fileUrl = fileReader.result;
          if (!this.justFiles.includes(fileUrl)) {
            this.file = fileUrl;
            this.files.push({
              image: this.file,
              type: fileToBeEntered.type.split("/")[1],
            });
            this.justFiles.push(this.file);
          } else {
            alert("Same picture uploaded");
          }
        };
        fileReader.onerror = () => {
          console.log("error");
        };
      } else {
        alert("Only Image files accepted");
      }
    },

    //open popup
    openPopup() {
      this.popupOpened = true;
    },
    cancelUpload() {
      this.latestImage = null;
      this.closePopup();
      console.log("Cancel Upload", this.files.length);
      if(this.files.length>0){
        let newArray = [];
        this.files.forEach((element,index) => {
          if(index!=this.files.length-1){
            newArray.push(element);
          }
        });
        this.files = newArray;
      }
    },
    //close popup
    closePopup() {
      this.popupOpened = false;
    },
    useUploadImage(){
      console.log("Use Upload Image",this.imageLink,this.files.length);
      this.handleImageUpload(this.imageLink,this.files,this.files.length-1);
    }
  },

  watch: {
    file: function() {
      console.log("File changed:", this.files);
      this.latestImage = this.files[this.files.length-1].image;
      console.log("Image link", this.imageLink);
      this.setImages(this.files, this.imageLink);
    },
  },

  props: ["setImages", "handleImageUpload", "imagesResult" , "deleteUploadedImage"],
};
</script>

<style lang="scss" scoped>
.cutsom-container-big {
  width: 75%;
  border: 2px dashed rgba(1, 1, 1, 0.3);
  height: 25rem;
  img {
    height: 3rem;
    width: auto;
  }
  a {
    text-decoration: underline;
    text-decoration-color: #0d6efd;
    &:hover {
      color: #0d6efd;
      text-decoration: underline;
      text-decoration-color: #0d6efd;
    }
  }
}
.cutsom-container-small {
  width: 45%;
  height: 25rem;
  padding: 0rem 1rem;
}
.custom-scope {
  background-color: #ddd;

  &.custom-scope-1 {
    height: 48%;
    width: 59%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &.custom-scope-2 {
    height: 48%;
    width: 39%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &.custom-scope-3 {
    height: 48%;
    width: 49%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &.custom-scope-4 {
    height: 48%;
    width: 49%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
.drag-enter {
  border: 2px dashed #0d6efd;
}
.custom-overlay {
  position: absolute;
  z-index: 100;
  height: 100%;
  width: 100%;
  background-color: rgba(1, 1, 1, 0.45);
  z-index: 1000;
  top: 0%;
}
.custom-popup-layout{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(1, 1, 1, 0.25);
  backdrop-filter: blur(2px);
  .custom-upload-popup{
    width: 70%;
    height: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .custom-upload-box{
      width:60%;
      height: 60%;
      position: relative;
      border: 2px solid #0d6efd;
      border-style: dashed;
      margin-bottom: 1rem;
    }
    .custom-button{
      border: 1px solid #0d6efd;
      border-radius: 5px;
      padding: 0.5rem 1rem;
      background: transparent;
      margin-right: 1rem;
    }
    .custom-button-1{
      border: 1px solid #0d6efd;
      border-radius: 5px;
      padding: 0.5rem 1rem;
      background-color: #0d6efd;
      color: #fff;
    }
  }
}
.custom-upload-text{
  font-size: 2.3rem;
}
.custom-image-container-2{
  flex-wrap: wrap;
  width: 80%;
  position: relative;
  .custom-image-display{
    width:30%;
    height: 14rem;
    margin-bottom: 1rem;
    position: relative;
    margin: 1rem 8px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .caption{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3rem;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      font-size: 1rem;
      padding: 0rem 5px;
      padding-top: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

@media only screen and (max-width: 600px) {
  .custom-container {
    display: flex;
    flex-direction: column !important;
    align-items: center;
  }
  .cutsom-container-big {
    width: 100%;
  }
  .cutsom-container-small {
    width: 100%;
    margin-top: 2rem;
  }
  .custom-upload-text{
    font-size: 1.4rem;
    text-align: center;
  }
  .custom-popup-layout{
    .custom-upload-popup{
      width: 95%;
      height: 60%;
      .custom-upload-box{
        width:90%;
        height: 60%;
        position: relative;
        border: 2px solid #0d6efd;
        border-style: dashed;
        margin-bottom: 1rem;
      }
    }
  }
}

</style>
