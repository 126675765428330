<template>
  <div class="h-100 bg-color-white py-1">
    <LightNavbar />
    <div class="h-100">
      <div
        class="
          w-100
          d-flex
          flex-column
          justify-content-center
          align-items-center
          position-relative
        "
      >
        <div
          class="custom-container-small d-flex flex-column justify-content-end"
        >
          <TopListingSteps :current="current" />
        </div>
        <div class="custom-container-full d-flex justify-content-start mt-1">
          <div
            class="custom-next-button color-white bg-color-blue rounded"
            v-if="current > 1"
            @click="goBackPage"
          >
            back
          </div>
        </div>
        <div
          class="
            custom-container
            position-relative
            d-flex
            justify-content-between
            align-items-start
          "
        >
          <!-- For listing page 1 -->
          <div class="custom-section" v-if="current == 1">
            <ListingOne
              :setHouseNo="setHouseNo"
              :setHouseName="setHouseName"
              :setContactNo="setContactNo"
              :setPincode="setPincode"
              :setDescription="setDescription"
              :city="city"
              :state="state"
              :country="country"
              :setAddressLine1="setAddressLine1"
              :setAddressLine2="setAddressLine2"
              :mapAddress="mapAddress"
              :setCountry="setCountry"
              :setState="setState"
              :setCity="setCity"
              :houseName ="houseName"
              :description ="description"
              :houseNo ="houseNo"
              :contactNo ="contactNo"
              :latitude ="latitude"
              :longitude ="longitude"
              :pincode ="pincode"
              :addressLine1 ="addressLine1"
              :addressLine2 ="addressLine2"
              :selectedCity ="selectedCity"
            />
          </div>
          <div class="custom-section" v-if="current == 1">
            <div
              style="font-size: 20px; font-weight: 600; margin-bottom: 1rem"
              class="color-black"
            >
              Drag the map to move around and double click to set the location
            </div>
            <Map :setCoordinates="setCoordinates" />

            <div>
              <div style="font-size: 20px; font-weight: 600">
                Address in Map
              </div>
              {{ mapAddress }}
            </div>
          </div>
          <!-- For listing page 1 end -->

          <!-- For listing page 2 -->
          <div class="w-100 custom-section-full" v-if="current == 2">
            <ListingTwo
              :guestsAllowed="guestsAllowed"
              :addGuestsAllowed="addGuestsAllowed"
              :subtractGuestsAllowed="subtractGuestsAllowed"
              :rooms="rooms"
              :addRooms="addRooms"
              :subtractRooms="subtractRooms"
              :beds="beds"
              :addBeds="addBeds"
              :subtractBeds="subtractBeds"
              :baths="baths"
              :addBaths="addBaths"
              :subtractBaths="subtractBaths"
              :balconies="balconies"
              :addBalconies="addBalconies"
              :subtractBalconies="subtractBalconies"
              :area="area"
              :setArea="setArea"
              :furnished="furnished"
              :setFurnished="setFurnished"
              :ams="ams"
              :setAmenityIndex="setAmenityIndex"
              :setListingTypeSingle="setListingTypeSingle"
              :setListingTypeMulti="setListingTypeMulti"
              :setIsPrivate="setIsPrivate"
              :isPrivate="isPrivate"
              :listingType="listingType"
              :usedAms="usedAms"
            />
          </div>
          <!-- End of listing page 2 -->
          <!-- For listing page 3 -->
          <div class="w-100 custom-section-full" v-if="current == 3">
            <ListingThree
              :setImages="setImages"
              :handleImageUpload="handleImageUpload"
              :imagesResult="imagesResult"
              :deleteUploadedImage="deleteUploadedImage"
            />
          </div>
          <!-- End of listing page 3 -->
          <!-- For listing page 4 -->
          <div class="w-100 custom-section-full" v-if="current == 4">
            <ListingFour
              :setadvanceGuestBooking="setadvanceGuestBooking"
              :setNoticeBeforeGuestArrives="setNoticeBeforeGuestArrives"
              :setDurationOfStay="setDurationOfStay"
              :setCurrency="setCurrency"
              :setBasePrice="setBasePrice"
              :setEarliestMoveInDate="setEarliestMoveInDate"
              :setSecurityDeposit="setSecurityDeposit"
              :setEstimatedPrice="setEstimatedPrice"
              :pricingDone="pricingDone"
              :setShortStayData="setShortStayData"
              :setLongStayData="setLongStayData"
              :setPricingDone="setPricingDone"
              :shortStayData="shortStayData"
              :longStayData="longStayData"
              :furnished="furnished"
            />
          </div>
          <!-- End of listing page 4 -->
          <!-- For listing page 5 -->
          <div class="w-100 custom-section-full" v-if="current == 5">
            <ListingFive
              :setMiscAminityIndex="setMiscAminityIndex"
              :miscAmenities="miscAmenities"
              :usedMiscAms="usedMiscAms"
            />
          </div>
          <!-- End of listing page 5 -->
        </div>
        <div class="custom-container-full d-flex justify-content-end">
          <div
            class="custom-next-button color-white bg-color-blue rounded"
            v-if="current < 4"
            @click="goNextPage"
          >
            Next
          </div>
          <div
            class="custom-next-button color-white bg-color-blue rounded"
            v-if="
              current == 4 && (pricingDone.shortStay || pricingDone.longStay)
            "
            @click="goNextPage"
          >
            Next
          </div>
          <div
            class="custom-next-button color-white bg-color-blue rounded"
            v-if="current == 5"
            @click="useFifthListingPage"
          >
            Add listing
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ListingOne from "@/views/newcomponents/ListingPageDetails/ListingPageOne.vue";
import ListingTwo from "@/views/newcomponents/ListingPageDetails/ListingPageTwo.vue";
import ListingThree from "@/views/newcomponents/ListingPageDetails/ListingPageThree.vue";
import ListingFour from "@/views/newcomponents/ListingPageDetails/ListingPageFour.vue";
import ListingFive from "@/views/newcomponents/ListingPageDetails/ListingPageFive.vue";
import TopListingSteps from "@/views/newcomponents/TopListingSteps/TopListingSteps.vue";
import LightNavbar from "@/views/newcomponents/LightNavbar/LightNavbar.vue";


import Map from "@/views/newcomponents/Mapbox/Map.vue";
//import Amenities from "@/mock_data/misc_amenities.json";

import {
  firstListingPage,
  secondListingPage,
  thirdListingPage,
  fourthListingPage,
  fifthListingPage,
  getActiveAminities,
  getMiscAmenities,
  fetchRevGeocodeDetails,
  uploadImageOneByOne,
  deleteHouseImage,
  fetchListingData,
  getHouseImages
} from "@/utils/api.js";

export default {
  data() {
    return {
      current: 1,
      editHouse: false,
      longPresent:false,
      shortPresent: false,

      //First page listing
      houseName: "",
      description: "",
      houseNo: "",
      contactNo: "",
      country: "",
      state: "",
      city: "",
      latitude: 0,
      longitude: 0,
      pincode: 0,
      addressLine1: "",
      addressLine2: "",
      mapAddress: "",
      //Second page listing
      listingType: "single",
      isPrivate: false,
      guestsAllowed: 0,
      rooms: 0,
      id: null,
      beds: 0,
      baths: 0,
      balconies: 0,
      area: 0,
      furnished: "",
      ams: [],
      usedAms: [],
      alreadySetAms:[],
      //Third page listing
      images: [],
      imagesLink: [],
      imagesResult: [],

      //Fourth Page Listing
      advanceGuestBookingSelected: "",
      noticeBeforeGuestArrivesSelected: "",
      durationOfStaySelected: "",
      currencySelected: "",
      basePrice: 0,
      earliestMoveInDate: "",
      securityDeposit: 0,
      estimatedPrice: 0,

      pricingDone: {
        shortStay: false,
        longStay: false,
      },
      shortStayData: {
        weekEndBasePrice: null,
        weekDayBasePrice: null,
        monthlyDiscounts: null,
        weeklyDiscounts: null,
        cancelStatus: "no",
        cancellationCharge: null,
      },

      longStayData: {
        price: null,
        cancelStatus: "no",
        cancellationCharge: null,
        deposit: null,
        noticePeriod: null,
      },
      //Arrays of fourth listing page

      //Fifth Page Listing apps

      miscAmenities: [],
      usedMiscAms: [],
      alreadySetMiscAms:[],
      selectedCity:{ title:"Bangalore" }
    };
  },
  methods: {
    // Methods for the first listing page

    util(obj, key, value) {
      obj[key] = value;
    },

    setShortStayData(val) {
      this.shortStayData = val;
    },
    setLongStayData(val) {
      this.longStayData = val;
    },

    setPricingDone(val) {
      this.pricingDone = val;
    },

    setHouseNo(val) {
      this.houseNo = val;
    },
    setCountry(val) {
      this.country = val;
    },
    setState(val) {
      this.state = val;
    },
    setCity(val) {
      this.city = val;
    },
    setHouseName(val) {
      this.houseName = val;
    },
    setDescription(val) {
      this.description = val;
    },
    setPincode(val) {
      this.pincode = val;
    },
    setAddressLine1(val) {
      this.addressLine1 = val;
    },
    setAddressLine2(val) {
      this.addressLine2 = val;
    },
    setContactNo(val) {
      this.contactNo = val;
    },
    async setCoordinates(lat, lng) {
      this.latitude = lat;
      this.longitude = lng;
      const loc_details = await fetchRevGeocodeDetails(
        this.longitude,
        this.latitude
      );

      const string_loc = loc_details.features[0].place_name.split(",");
      this.country = string_loc[string_loc.length - 1].trim();
      this.state = string_loc[string_loc.length - 2].trim();
      this.city = string_loc[string_loc.length - 3].trim();
      console.log(`eee ${this.state}`);
      this.mapAddress = loc_details.features[1].place_name;
    },

    setListingTypeSingle() {
      this.listingType = "single";
    },

    setListingTypeMulti() {
      this.listingType = "multi";
    },
    setIsPrivate(val) {
      this.isPrivate = val;
    },

    // Methods for the second listing page
    addGuestsAllowed() {
      this.guestsAllowed += 1;
    },

    subtractGuestsAllowed() {
      if (this.guestsAllowed > 0) {
        this.guestsAllowed -= 1;
      }
    },

    addRooms() {
      this.rooms += 1;
    },

    subtractRooms() {
      if (this.rooms > 0) {
        this.rooms -= 1;
      }
    },

    addBeds() {
      this.beds += 1;
    },

    subtractBeds() {
      if (this.beds > 0) {
        this.beds -= 1;
      }
    },

    addBaths() {
      this.baths += 1;
    },
    subtractBaths() {
      if (this.baths > 0) this.baths -= 1;
    },

    addBalconies() {
      this.balconies += 1;
    },

    subtractBalconies() {
      if (this.balconies > 0) {
        this.balconies -= 1;
      }
    },

    setFurnished(val) {
      if (val.includes("Furnished")) this.furnished = 1;

      if (val.includes("Semi-Furnished")) this.furnished = 2;
      if (val.includes("Unfurnished")) this.furnished = 3;
    },

    setArea(val) {
      this.area = val;
    },

    // Amenities functions
    setAmenityIndex(id) {
      console.log("Before:", this.usedAms);
      if (this.usedAms.includes(id)) {
        this.usedAms = this.usedAms.filter((item) => item != id);
      } else {
        this.usedAms.push(id);
      }

      console.log("Now:", this.usedAms);
    },

    //Methods for third listing page
    setImages(val, val1) {
      this.images = val;
      this.imagesLink = val1;
      console.log("Images changed", this.images, this.imagesLink);
    },

    //Methods for fourth listing page
    setadvanceGuestBooking(val) {
      this.advanceGuestBookingSelected = val;
    },
    setNoticeBeforeGuestArrives(val) {
      this.noticeBeforeGuestArrivesSelected = val;
    },
    setDurationOfStay(val) {
      this.durationOfStaySelected = val;
    },
    setCurrency(val) {
      this.currencySelected = val;
    },
    setBasePrice(val) {
      this.basePrice = val;
    },
    setEarliestMoveInDate(val) {
      this.earliestMoveInDate = val;
    },
    setSecurityDeposit(val) {
      this.securityDeposit = val;
    },
    setEstimatedPrice(val) {
      this.estimatedPrice = val;
    },

    //fifth page methods
    async useGetActiveAminities() {
      console.log("INSIDE GET ACTIVE");
      const data = await getActiveAminities();

      if (data) {
        console.log("GOT DATA");
        console.log(data);
        this.ams = [...data.amenities];
        console.log("Youtube filler", this.ams);
      } else {
        //If there is no data present condition
        console.log(data);
      }
    },

    async useGetActiveMiscAminites() {
      const data = await getMiscAmenities();

      if (data) {
        console.log("This is the data:", data);
        this.miscAmenities = [...data.amenities];
      } else {
        console.log(data);
      }
    },

    setMiscAminityIndex(id) {
      console.log("Before:", this.usedMiscAms);
      if (this.usedMiscAms.includes(id)) {
        this.usedMiscAms = this.usedMiscAms.filter((item) => item != id);
      } else {
        this.usedMiscAms.push(id);
      }

      console.log("Now:", this.usedMiscAms);
    },

    //Next and back functionalities
    async goNextPage() {
      if (
        this.current === 1 &&
        this.addressLine1 &&
        this.houseNo &&
        this.houseName &&
        this.description &&
        this.addressLine1 &&
        this.country &&
        this.state &&
        this.city &&
        this.pincode 
      ) {
        this.useFirstListingPage();
      } else if (
        this.current === 2 &&
        this.area &&
        this.furnished &&
        this.listingType &&
        this.usedAms.length > 0 &&
        this.guestsAllowed
      ) {
        this.useSecondListingPage();
      } else if (this.current === 3 && this.imagesResult.length > 3) {
        this.current = 4;
      } else if (this.current === 4) {
        this.useFourthListingPage();
      } else {
        this.$toasted.error("Please fill all the fields", {
          position: "top-center",
          duration: 5000,
          action: {
            text: "Cancel",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        });
      }
    },
    async goBackPage() {
      this.current -= 1;
    },
    async finishAddingListing() {
      if(this.editHouse){
        this.id=this.$route.params.id;
      }
      this.$router.push({path:`listedpage/${this.id}`});
      console.log("done");
    },

    //Listing page api calls

    async useFetchListingData() {
      this.id = this.$route.params.id;
      console.log("House to be updated has Id : >>>>", this.id);
      const data = await fetchListingData(this.id);
      console.log("House fetch details data", data);
      console.log("setting house data")

      //Setting up data

      //First page
      this.houseNo = data.house.house_no;
      this.latitude = data.house.latitude;
      this.longitude = data.house.longitude;
      this.addressLine1 = data.house.address_line_1;
      this.addressLine2 = data.house.address_line_2;
      this.houseName = data.house.house_name;
      this.country = data.house.country;
      this.state = data.house.state;
      this.city = data.house.city;
      this.pincode = data.house.pincode;
      this.contactNo = data.house.contact_no;
      this.description = data.house.description;
      console.log("setting house data done")


      //Second page
      this.guestsAllowed = data.house.guests_allowed;
      this.rooms = data.house.rooms;
      this.beds = data.house.beds;
      this.baths = data.house.baths;
      this.balconies = data.house.balconies;
      this.area = data.house.area;
      this.furnished = data.house.furnished; // Needs to be shown with CSS

      this.listingType = data.house.listing_type;
      this.isPrivate = data.house.is_private;

      var amenities = data.amenities;
      amenities.forEach((item) => {
        this.alreadySetAms.push(item.id);
      });

      this.usedAms = [...this.alreadySetAms];

      console.log(
        "Already added amenities are :-",
        this.alreadySetAms,
        this.usedAms
      );

      //Third page (waiting to get the images)

      //Fourth page

      if (data.long_stay) {
        this.longPresent = true;
        this.longStayData.price = data.long_stay.price;
        this.longStayData.cancelStatus = data.long_stay.cancel_status;
        this.longStayData.cancellationCharge =
          data.long_stay.cancellation_charge;
        this.longStayData.deposit = data.long_stay.deposit;
        this.longStayData.noticePeriod = data.long_stay.notice_period;
        this.pricingDone.longStay = true;
      }

      if (data.short_stay) {
        this.shortPresent = true
        this.shortStayData.weekEndBasePrice =
          data.short_stay.weekend_base_price;
        this.shortStayData.weekDayBasePrice =
          data.short_stay.week_day_base_price;
        this.shortStayData.monthlyDiscounts = data.short_stay.monthly_discounts;
        this.shortStayData.weeklyDiscounts = data.short_stay.weekly_discounts;
        this.shortStayData.cancelStatus = data.short_stay.cancel_status;
        this.shortStayData.cancellationCharge =
          data.short_stay.cancellation_charge;
        this.pricingDone.shortStay = true;
      }
      console.log(this.shortStayData)

      //Fifth page amenities
      var miscAmen = data.misc_amenities;
      miscAmen.forEach((item) => {
        this.alreadySetMiscAms.push(item.id);
      });

      this.usedMiscAms = [...this.alreadySetMiscAms];

      console.log(
        "Already added misc amenities are :-",
        this.alreadySetMiscAms,
        this.usedMiscAms
      );
    },
    async useGetImages() {
      const data = await getHouseImages(this.id);
      this.imagesResult = [...data.data];
      console.log("Existing images:", this.existingImages);
    },
    async useFirstListingPage() {
      console.log("Inside first listing page api call");
      const loc_details = await fetchRevGeocodeDetails(
        this.longitude,
        this.latitude
      );
      // console.log('Yeh hai details of map coord:', loc_details);
      const string_loc = loc_details.features[0].place_name.split(",");
      console.log(
        "Yeh hai details of map coord:",
        string_loc[string_loc.length - 1],
        string_loc[string_loc.length - 2],
        string_loc[string_loc.length - 3]
      );

      const details = {
        house_name: this.houseName,
        description: this.description,
        house_no: this.houseNo,
        latitude: this.latitude,
        longitude: this.longitude,
        address_line_1: this.addressLine1,
        address_line_2: this.addressLine2,
        country: this.country,
        state: this.state,
        city: this.selectedCity.title,
        pincode: this.pincode,
        contact_no: this.contactNo,
      };

      console.log(this.editHouse);

      // const details = {
      //   house_no: "777",
      //   latitude: "23.34",
      //   longitude: "23.5",
      //   address_line_1: "adde",
      //   country: "India",
      //   city: "Hugli",
      //   state: "WB",
      //   house_name: "Purana Ghar",
      //   pincode: 7646423,
      //   contact_no: "1234567690",
      //   description: "ksbgila"
      // }
      if (this.editHouse) {
        const details = {
          house_name: this.houseName,
          description: this.description,
          house_no: this.houseNo,
          latitude: this.latitude,
          longitude: this.longitude,
          address_line_1: this.addressLine1,
          address_line_2: this.addressLine2,
          country: this.country,
          state: this.state,
          city: this.city,
          pincode: this.pincode,
          contact_no: this.contactNo,
          house_id: this.id,
        };
        console.log("Edit house");
        const data = await firstListingPage(details);
        if (data.success) {
          console.log("Data received :", data);
          if(!this.editHouse){
            this.id = data.house_id;
          }
          console.log("Id being changed:", this.id);
          this.current += 1;
        } else {
          alert("Request failed! Try again!");
        }
        return;
      }

      const data = await firstListingPage(details);
      if (data.success) {
        console.log("Data received :", data);
        this.id = data.house_id;
        console.log("Id being changed:", this.id);
        this.current += 1;
      } else {
        alert("Request failed! Try again!");
      }
    },

    async useSecondListingPage() {
      console.log("Inside second listing page api call");
      console.log(this.isPrivate)

      const details = {
        guests_allowed: this.guestsAllowed,
        rooms: this.rooms,
        id: this.id,
        beds: this.beds,
        baths: this.baths,
        area: parseFloat(this.area),
        furnished: this.furnished,
        balconies: this.balconies,
        ams: this.usedAms,
        listing_type: this.listingType,
        is_private: this.isPrivate,
      };

      const data = await secondListingPage(details);
      if (data.success) {
        console.log("Data second(second page):", data);
        this.current += 1;
      } else {
        alert("Request failed! Try again!");
      }
    },

    async useThirdListingPage() {
      const data = await thirdListingPage(
        this.id,
        this.imagesLink,
        this.images
      );

      if (data.success) {
        console.log(data);
        this.current += 1;
      } else {
        alert("Request failed! Try again!");
      }
    },

    async useFourthListingPage() {
      const longStayDetails = {
        id: this.id,
        price: this.longStayData.price,
        cancel_status: this.longStayData.cancelStatus,
        cancellation_charge: this.longStayData.cancellationCharge,
        deposit: this.longStayData.deposit,
        notice_period: this.longStayData.noticePeriod,
      };

      const shortStayDetails = {
        id: this.id,
        week_end_base_price: this.shortStayData.weekEndBasePrice,
        week_day_base_price: this.shortStayData.weekDayBasePrice,
        monthly_discounts: this.shortStayData.monthlyDiscounts,
        weekly_discounts: this.shortStayData.weeklyDiscounts,
        cancel_status: this.shortStayData.cancelStatus,
        cancellation_charge: this.shortStayData.cancellationCharge,
      };

      const data = await fourthListingPage(
        shortStayDetails,
        longStayDetails,
        this.pricingDone,
        this.longPresent,
        this.shortPresent
      );
      if (data.resShort.success || data.resLong.success) {
        console.log("stays gone");
        this.current += 1;
        if(data.lDone){
          this.longPresent = true;
        }
        else {
          this.longPresent = false;
        }
        if(data.sDone){
          this.shortPresent = true
        }
        else{
          this.shortPresent = false
        }
      } else {
        alert("Request failed! Try again!");
      }
    },

    async useFifthListingPage() {
      const apiDetails = {
        id: this.id,
        ams: this.usedMiscAms,
      };

      const data = await fifthListingPage(apiDetails);
      if (data.success) {
        console.log(apiDetails);
        this.current += 1;
        this.finishAddingListing();
      } else {
        alert("Request failed! Try again!");
      }
    },

    async handleImageUpload(imageLink, images, index) {
      const data = await uploadImageOneByOne(this.id, imageLink, images, index);
      this.imagesResult = [...data.data];
    },

    async deleteUploadedImage(imageId) {
      const apiDetails = {
        id: this.id,
        image_ids: [imageId],
      };
      const data = await deleteHouseImage(apiDetails);
      this.imagesResult = [...data.data];
    },
  },

  watch: {
    //function to check houseNo
    houseNo: function () {
      console.log("House no. changed", this.houseNo);
    },
    //function to check addressLine change
    addressLine1: function () {
      console.log("AddressLine 1 changed", this.addressLine1);
    },
    //function to check addressLine2 change
    addressLine2: function () {
      console.log("Addressline 2. changed", this.addressLine2);
    },
    //function to check listingType change
    listingType: function () {
      console.log("Listing type changed", this.listingType);
    },

    // function to verify whether latitude is being set or not
    latitude: function () {
      console.log("Coordinates changed");
      console.log("Lat: ", this.latitude, " Lng:", this.longitude);
    },

    //function to verify change in isPrivate
    isPrivate: function () {
      console.log("Is private changed:", this.isPrivate);
    },

    //function to watch change of furnished value
    furnished: function () {
      console.log("Furnished changed to:", this.furnished);
    },

    area: function () {
      console.log("Area variable getting chnaged", this.area);
    },

    //fourth listing page watches

    advanceGuestBookingSelected: function () {
      console.log(this.advanceGuestBookingSelected, "Changed");
    },

    noticeBeforeGuestArrivesSelected: function () {
      console.log(this.noticeBeforeGuestArrivesSelected, "Changed");
    },

    durationOfStaySelected: function () {
      console.log(this.durationOfStaySelected, "Changed");
    },

    currencySelected: function () {
      console.log(this.currencySelected, "Changed");
    },
    basePrice: function () {
      console.log(this.basePrice, "Changed");
    },
    earliestMoveInDate: function () {
      console.log(this.earliestMoveInDate, "changed");
    },
    securityDeposit: function () {
      console.log(this.securityDeposit, "changed");
    },
    estimatedPrice: function () {
      console.log(this.estimatedPrice, "changed");
    },

    miscAmenities: {
      handler: function () {
        // Log it
        console.log(this.miscAmenities, "changed");
      },
      deep: true,
    },
  },

  mounted() {
    this.useGetActiveAminities();
    this.useGetActiveMiscAminites();
    self = this;
    navigator.geolocation.watchPosition(
      function (position) {
        console.log("i'm tracking you!");
      },
      function (error) {
        if (error.code == error.PERMISSION_DENIED)
          alert("Please enable location and try Again");
        self.$router.push("/pages/userdashboard/");
      }
    );
    if (this.$route.params.id) {
      this.editHouse = true;
      this.useFetchListingData();
      this.useGetImages();
      console.log("edit hai vro");
    }
    window.onbeforeunload = function() {
  return "Data will be lost if you leave the page, are you sure?";
};
  },

  components: {
    ListingOne,
    TopListingSteps,
    Map,
    ListingTwo,
    ListingThree,
    ListingFour,
    ListingFive,
    LightNavbar,
  },
};
</script>
<style lang="scss" scoped>
.custom-container-small {
  width: 80%;
  min-height: 20vh;
}
.custom-container {
  width: 80%;
  height: 75%;
  padding-top: 3rem;
  .custom-section {
    min-height: 60vh;

    width: 50%;
    padding: 0 2rem;
    padding-right: 4rem;
    margin-bottom: 1rem;
  }
  .custom-section-full {
    min-height: 50vh;
    width: 100%;
    margin-bottom: 1rem;
  }
}
.custom-container-full {
  width: 80%;
  padding: 1rem;
}
.custom-next-button {
  width: 8rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media only screen and (max-width: 650px) {
  .custom-container-small {
    width: 100%;
    height: 20%;
  }
  .custom-container {
    flex-direction: column;
    width: 95%;
    .custom-section {
      width: 100%;
      padding: 0 0.5rem;
      height: 150%;
    }
    .custom-section-full {
      height: 250%;
    }
  }
}
</style>
