<template>
  <div id="map" @click="getlatlong()"></div>
</template>

<script>
import mapboxgl from "mapbox-gl";
export default {
  data() {
    return {
      mapToken:
        "pk.eyJ1IjoicmlzaGktcmFqLXJveSIsImEiOiJja3N1ZGdsNmwxZXZrMzJvZHhlMDh1M2tjIn0.V1a6wQRKv3J-fNnSGWY6ig",
      map: {},
      marker: "",
      latitude: 0,
      longitude: 0,
    };
  },

  methods: {
    loadMap() {
          try{

            if ("geolocation" in navigator) { 
                navigator.geolocation.getCurrentPosition(position => { 
                mapboxgl.accessToken = this.mapToken;
                this.latitude = position.coords.latitude
                this.longitude = position.coords.longitude
                //this.setMarkeronMap();
                
                console.log("entering here")
                this.map = new mapboxgl.Map({
                    container: "map", // container ID
                    style: 'mapbox://styles/mapbox/streets-v11', // style URL
                    center: [position.coords.longitude,position.coords.latitude], // starting position [lng, lat]
                    zoom: 13 // starting zoom
                });

                console.log("Map value is:",this.map )

                const geolocate = new mapboxgl.GeolocateControl({
                        positionOptions: {
                            enableHighAccuracy: true
                        },
                        trackUserLocation: true
                        });

                    this.map.addControl(geolocate, "top-right")
            
                })
            }

            else{
                mapboxgl.accessToken = this.mapToken;
                console.log("entering here")
                this.map = new mapboxgl.Map({
                    container: "map", // container ID
                    style: 'mapbox://styles/mapbox/streets-v11', // style URL
                    center: [78.9629, 20.5937 ], // starting position [lng, lat]
                    zoom:6 // starting zoom
                });

                console.log("Map value is:",this.map )

                const geolocate = new mapboxgl.GeolocateControl({
                        positionOptions: {
                            enableHighAccuracy: true
                        },
                        trackUserLocation: true
                        });

                    this.map.addControl(geolocate, "top-right")
                
            }
            
            
            
            
            }
            catch(err){
                console.log("Error in Map", err)
            }
    },
    setMarkeronMap() {
      if (this.marker) {
        this.marker.remove();
      }

      this.marker = new mapboxgl.Marker()
        .setLngLat({ lon: this.longitude, lat: this.latitude })
        .addTo(this.map);

      console.log("added to map");
      this.map.flyTo({
        center: { lon: this.longitude, lat: this.latitude },
        zoom: 13,
      });

      console.log("Map marking complete!");
      this.useSetCoordinates();
    },
    useSetCoordinates() {
      this.setCoordinates(this.latitude, this.longitude);
    },

    getlatlong() {
      this.map.on("click", (e) => {
        this.latitude = JSON.stringify(e.lngLat.lat);
        this.longitude = JSON.stringify(e.lngLat.lng);
      });
      console.log("Latitude:", this.latitude, " Longitude:", this.longitude);
      console.log("Projecting a marker in the map:");
      this.setMarkeronMap();
    },
  },
  mounted() {
    this.loadMap();
  },
  watch:{
        latitude: function(){
            console.log('Latitude Changed: ', this.latitude)
            console.log('Longitude Changed: ', this.longitude)
            this.setMarkeronMap();
        }

    },

  props: ["setCoordinates"],
};
</script>

<style lang="scss" scoped>
#map {
  width: 100%;
  margin: 0 auto;
  min-height: 40rem;
  background-color: #fff;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 550px) {
  #map {
    width: 100%;
    min-height: 25rem;
  }
}
</style>
