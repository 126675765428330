<template>
  <div class="jadecaps-logo-item">
      <img :src="Image" alt="">
  </div>
</template>

<script>
import Image from"@/assets/images/ico/JadeCaps_logo_F-01.png";
export default {
  data() {
    return {
      Image
    };
  }
}
</script>

<style lang="scss" scoped>
    .jadecaps-logo-item{
      height: 3rem;
      width: 9rem;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 100%;
        height: auto;
      }
    }
</style>