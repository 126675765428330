<template>
  <div class="w-100 h-100 position-relative">
    <div class="d-flex flex-column">
      <div class="font-large color-black font-butler mb-1">
        Miscellaneous Amenities
      </div>
      <div class="w-100 d-flex justify-content-start flex-wrap">
        <div
          v-for="(item, index) in miscAmenities"
          :key="index"
          v-bind:id="'miscItem'+item.id"
          class="custom-box-feature bg-color-white d-flex justify-content-start align-items-center px-1 py-1  mb-2 mr-1"
          @click="checkEvent($event, item.id)"
        >
          <img
            :src="item.icon"
            class="mr-1"
            alt=""
          />
          <div class="color-black font-small">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import Amenities from "@/mock_data/misc_amenities.json";
//import { getActiveAminities } from '@/utils/api.js';
export default {
  data() {
    return {
      amenities: [],
    };
  },
  methods: {

    useSetFeatureActive(e) {
      e.target.classList.toggle("active");
    },

    checkEvent(e, id){
      this.useSetFeatureActive(e);
      this.setMiscAminityIndex(id);
    },
    setActiveOnMount(){
      console.log(this.usedMiscAms)
      for(let i=0; i<this.usedMiscAms.length; i++){
        document.getElementById(`miscItem${this.usedMiscAms[i]}`).classList.add("active")
      }
    },

    // async useGetActiveAminities(){
    //   try{
    //     const data = await getActiveAminities();
    //     if(data){

    //     console.log(data);
    //     data.forEach((item) => {
    //       if(!item.success)
    //         this.amenities.push({name : item.name, icon : '', value : false})
    //     })

    //     console.log(this.amenities);
    //     }
    //     else{
    //       //If there is no data present condition
    //       console.log(data);

    //     }

    //   }

    //   catch(err){
    //     console.log(err);
    //     //this.$router.go();
    //   }

    // }
  },
  mounted() {
    this.amenities = this.miscAmenities;
    console.log(this.amenities, this.miscAmenities);
    this.setActiveOnMount()
  },

  props: ["setMiscAminityIndex", "miscAmenities", "usedMiscAms"],
};
</script>
<style lang="scss">
.custom-box-feature {
  min-width: 12rem;
  height: 3rem;
  border-radius: 7px;
  box-shadow: 0px 10px 20px -5px rgba($color: #000000, $alpha: 0.09);
  cursor: pointer;
  transition: 0.3s ease;
  margin-right: 1rem;

  &.active {
    border: 1px solid rgba($color: #000000, $alpha: 0.7);
  }
  &:hover {
    border: 1px solid rgba($color: #000000, $alpha: 0.7);
  }
  img {
    height: 1.7rem;
    width: auto;
    pointer-events: none;
  }
  div {
    pointer-events: none;
  }
}
</style>
