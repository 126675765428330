<template>
  <div class="h-100 w-100">
    <div
          class="d-flex flex-column justify-content-between  color-black font-butler font-large mb-2"
        >
          Please tell us about
          <br />
          your property
        </div>
        <div class="font-weight-600 mb-1">Listing type</div>
    <div class="custom-input-group d-flex justify-content-start mb-2">
      <div
        class="custom-radio-group d-flex align-items-center mr-3 color-black"
      >
        <input type="radio" class="" name="listingtype" @click="useListingTypeSingle" :checked="(!this.$route.params.id)?true:(listingType=='single')?true:false"/>
        <label
          for=""
          class="font-regular color-black"
          
          >
          
          Single unit
          </label>
      </div>
      <div class="custom-radio-group d-flex align-items-center ">
        <input type="radio" class="" name="listingtype" @click="useListingTypeMulti" :checked="(listingType=='multi')?true:false"/>
        <label
          for=""
          class="font-regular color-black"
          
          >
          
          Multi unit
        </label>
      </div>
    </div>
    <div class="select-holder">
      <div
      class="custom-select-card w-full d-flex justify-content-start align-items-center mb-2"
      :class="{'active': !isPrivate}"
      @click="useSetIsPrivate(false)"
    >
      <img src="../../../assets/icons/house.png" alt="" class="mr-3" />
      <div class="d-flex flex-column">
        <div class="font-mid-small font-weight-600 color-black">
          Entire house
        </div>
        <div class="font-small" style="pointer-events:none;">The entire unit is rented on one lease</div>
      </div>
    </div>
    <div
      class="custom-select-card w-full d-flex justify-content-start align-items-center mb-2"
      :class="{'active':isPrivate}"
      @click="useSetIsPrivate(true)"
    >
      <img src="../../../assets/icons/bed.png" alt="" class="mr-3" />
      <div class="d-flex flex-column">
        <div class="font-mid-small font-weight-600 color-black">
          Private Room
        </div>
        <div class="font-small" style="pointer-events:none;">One private bedroom within the unit.</div>
      </div>
    </div>
    </div>
    <div
      class="custom-container d-flex justify-content-center align-items-center h-100"
    >
    
      <div
        class="custom-subcontainer custom-border-one d-flex flex-column w-50 h-100 pr-4"
      >
        <!-- <div
          class="d-flex flex-column justify-content-between  color-black font-butler font-large mb-2"
        >
          Please tell us about
          <br />
          your property
        </div> -->
        <div class="font-regular mb-2">
          Please provide some basic details about the property
        </div>
        <div class="custom-details-group">
          <!-- Guests allowed -->
          <div class="custom-details d-flex mb-1">
            <div
              class="custom-button-container mr-2 d-flex justify-content-center align-items-center"
            >
              <div
                class="custom-blue-button bg-color-blue color-white d-flex justify-content-center align-items-center"
                @click="useAddGuestsAllowed"
              >
                <div class="custom-plus-sign">
                  <div class="line"></div>
                  <div class="line"></div>
                </div>
              </div>
              <input
                type="text"
                disabled
                v-model="guestsAllowed"
                class="text-center"
              />
              <div
                class="custom-blue-button custom-left bg-color-blue color-white d-flex justify-content-center align-items-center"
                @click="useSubtractGuestsAllowed"
              >
                <div class="custom-minus-sign">
                  <div class="line"></div>
                </div>
              </div>
            </div>

            <div>Guests Allowed</div>
          </div>
          <!-- Guests Allowed Ends -->
          <!-- Bedrooms starts -->
          <div class="custom-details d-flex mb-1">
            <div
              class="custom-button-container mr-2 d-flex justify-content-center align-items-center"
            >
              <div
                class="custom-blue-button bg-color-blue color-white d-flex justify-content-center align-items-center"
                @click="useAddRooms"
              >
                <div class="custom-plus-sign">
                  <div class="line"></div>
                  <div class="line"></div>
                </div>
              </div>
              <input type="text" disabled v-model="rooms" class="text-center" />
              <div
                class="custom-blue-button custom-left bg-color-blue color-white d-flex justify-content-center align-items-center"
                @click="useSubtractRooms"
              >
                <div class="custom-minus-sign">
                  <div class="line"></div>
                </div>
              </div>
            </div>
            <div>Bedrooms</div>
          </div>
          <!-- Bed rooms end -->
          <!-- Beds start -->
          <div class="custom-details d-flex mb-1">
            <div
              class="custom-button-container mr-2 d-flex justify-content-center align-items-center"
            >
              <div
                class="custom-blue-button bg-color-blue color-white d-flex justify-content-center align-items-center"
                @click="useAddBeds"
              >
                <div class="custom-plus-sign">
                  <div class="line"></div>
                  <div class="line"></div>
                </div>
              </div>
              <input type="text" disabled v-model="beds" class="text-center" />
              <div
                class="custom-blue-button custom-left bg-color-blue color-white d-flex justify-content-center align-items-center"
                @click="subtractBeds"
              >
                <div class="custom-minus-sign">
                  <div class="line"></div>
                </div>
              </div>
            </div>
            <div>Beds</div>
          </div>
          <!-- Beds end -->

          <!-- Baths -->
          <div class="custom-details d-flex mb-1">
            <div
              class="custom-button-container mr-2 d-flex justify-content-center align-items-center"
            >
              <div
                class="custom-blue-button bg-color-blue color-white d-flex justify-content-center align-items-center"
                @click="useAddBaths"
              >
                <div class="custom-plus-sign">
                  <div class="line"></div>
                  <div class="line"></div>
                </div>
              </div>
              <input type="text" disabled v-model="baths" class="text-center" />
              <div
                class="custom-blue-button custom-left bg-color-blue color-white d-flex justify-content-center align-items-center"
                @click="useSubtractBaths"
              >
                <div class="custom-minus-sign">
                  <div class="line"></div>
                </div>
              </div>
            </div>
            <div>Bathrooms</div>
          </div>
          <!-- Baths -->
          <!-- Balconies start -->
          <div class="custom-details d-flex mb-1">
            <div
              class="custom-button-container mr-2 d-flex justify-content-center align-items-center"
            >
              <div
                class="custom-blue-button bg-color-blue color-white d-flex justify-content-center align-items-center"
                @click="useAddBalconies"
              >
                <div class="custom-plus-sign">
                  <div class="line"></div>
                  <div class="line"></div>
                </div>
              </div>
              <input
                type="text"
                disabled
                v-model="balconies"
                class="text-center"
              />
              <div
                class="custom-blue-button custom-left bg-color-blue color-white d-flex justify-content-center align-items-center"
                @click="useSubtractBalconies"
              >
                <div class="custom-minus-sign">
                  <div class="line"></div>
                </div>
              </div>
            </div>
            <div>Balconies</div>
            <!-- Balconies end -->
          </div>
          <!-- Balconies end -->
        </div>
        <!-- Area start -->
        <div class="color-black font-mid-large font-butler mb-1">
          Add area details(Sq. Ft)
        </div>
        <div class="custom-input-area mb-2">
          <input
            type="text"
            placeholder="Area in Sq Ft."
            class="w-100"
            v-model="area"
          />
        </div>
        <!-- Area end -->
        <!-- Furnished start -->
        <div
          class="custom-quality-wrapper w-100 d-flex justify-content-between align-items-center"
        >
          <div class="custom-quality" @click="useQualityActiveToggle($event)" id="f1">
            Furnished
          </div>
          <div class="custom-quality" @click="useQualityActiveToggle($event)" id="f2">
            Semi-Furnished
          </div>
          <div class="custom-quality" @click="useQualityActiveToggle($event)" id="f3">
            Unfurnished
          </div>
        </div>
        <!-- Furnished end -->
      </div>
      <div class="custom-subcontainer d-flex flex-column w-50 h-100 pl-4">
        <div>
          <div class="color-black font-mid-large font-butler mb-1">
            Standout Amenities
          </div>
          <div
            class="d-flex justify-content-start align-items-center flex-wrap mb-1"
            
          >
            <div
              class="custom-box-feature bg-color-white d-flex justify-content-start align-items-center px-1 mb-1"
              v-for="(item,index) in ams" :key="index" v-bind:id="'amsItem'+item.id"
              @click="clickEvent($event, item.id)"
            >
              <img
                :src="item.icon"
                class="mr-1"
                alt=""
              />
              <div class="color-black font-small">{{ item.name }}</div>
            </div>
          
          </div>
        </div>
        <!-- <div>
          <div
            class="d-flex justify-content-start align-items-center flex-wrap mb-1"
          >
            <div
              class="custom-box-feature bg-color-white d-flex justify-content-start align-items-center px-1 mb-1"
              @click="useToggleWifi($event)"
            >
              <img src="../../../assets/icons/wifi.png" class="mr-1" alt="" />
              <div class="color-black font-small">Wifi</div>
            </div>
            <div
              class="custom-box-feature bg-color-white d-flex justify-content-start align-items-center px-1 mb-1"
              @click="useToggleTv($event)"
            >
              <img
                src="../../../assets/icons/television.png"
                class="mr-1"
                alt=""
              />
              <div class="color-black font-small">TV</div>
            </div>
            <div
              class="custom-box-feature bg-color-white d-flex justify-content-start align-items-center px-1 mb-1"
              @click="useToggleKitchen($event)"
            >
              <img src="../../../assets/icons/fridge.png" class="mr-1" alt="" />
              <div class="color-black font-small">Kitchen</div>
            </div>
            <div
              class="custom-box-feature bg-color-white d-flex justify-content-start align-items-center px-1 mb-1"
              @click="useToggleCarParking($event)"
            >
              <img src="../../../assets/icons/car.png" class="mr-1" alt="" />
              <div class="color-black font-small">Car Parking</div>
            </div>
            <div
              class="custom-box-feature bg-color-white d-flex justify-content-start align-items-center px-1 mb-1 "
              @click="useToggleAc($event)"
            >
              <img
                src="../../../assets/icons/ice-crystal.png"
                class="mr-1"
                alt=""
              />
              <div class="color-black font-small">AC</div>
            </div>
          </div>
        </div> -->

        <!-- <div>
          <div class="color-black font-mid-large font-butler mb-1">
            Safety Items
          </div>
          <div
            class="d-flex justify-content-start align-items-center flex-wrap mb-1"
          >
            <div
              class="custom-box-feature bg-color-white d-flex justify-content-start align-items-center px-1 mb-1"
              @click="useToggleFirstAid($event)"
            >
              <img
                src="../../../assets/icons/first-aid.png"
                class="mr-1"
                alt=""
              />
              <div class="color-black font-small">First Aid Kit</div>
            </div>
            <div
              class="custom-box-feature bg-color-white d-flex justify-content-start align-items-center px-1 mb-1"
              @click="useToggleSmokeAlarm($event)"
            >
              <img src="../../../assets/icons/alarm.png" class="mr-1" alt="" />
              <div class="color-black font-small">Smoke Alarm</div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      number: 3,
      // isPrivate:false,
    };
  },
  methods: {
    useQualityActiveToggle(e) {
      const qualities = document.querySelectorAll(".custom-quality");
      for (var i = 0; i < qualities.length; i++) {
        qualities[i].classList.remove("active");
      }
      e.target.classList.add("active");
      console.log(e);
      this.useSetFurnished(e.target.innerHTML);
    },
    useSetFeatureActive(e) {
      e.target.classList.toggle("active");
    },

    setActiveOnMount(){
      console.log(this.usedAms)
      for(let i=0; i<this.usedAms.length; i++){
        document.getElementById(`amsItem${this.usedAms[i]}`).classList.add("active")
      }
    },

    setFurnishedOnMount(){
      console.log(this.furnished)
      switch (parseInt(this.furnished)) {
        case 1: document.getElementById(`f1`).classList.add("active")
          
          break;
        case 2: document.getElementById(`f2`).classList.add("active")
          
          break;
        case 3: document.getElementById(`f3`).classList.add("active")
          
          break;
      
        default:
          break;
      }
    },

    //Change guestsAllowed functions
    useAddGuestsAllowed() {
      this.addGuestsAllowed();
    },

    useSubtractGuestsAllowed() {
      this.subtractGuestsAllowed();
    },

    //Change bedrooms functions
    useAddRooms() {
      this.addRooms();
    },
    useSubtractRooms() {
      this.subtractRooms();
    },

    //Change Bed functions
    useAddBeds() {
      this.addBeds();
    },

    useSubtractBeds() {
      this.subtractBeds();
    },

    //Functions to change baths

    useAddBaths() {
      this.addBaths();
    },
    useSubtractBaths() {
      this.subtractBaths();
    },

    // Functions to change balconies
    useAddBalconies() {
      this.addBalconies();
    },

    useSubtractBalconies() {
      this.subtractBalconies();
    },

    //Function to change furnished value
    useSetFurnished(val) {
      this.setFurnished(val);
    },

    //Function to change area value
    useSetArea() {
      this.setArea(this.area);
    },

    // Listing type Func
    useListingTypeSingle() {
      this.setListingTypeSingle();
    },

    useListingTypeMulti() {
      this.setListingTypeMulti();
    },
    useSetIsPrivate(val){
      console.log(val);
      this.isPrivate = val;
      this.setIsPrivate(val);
    },

    //Amenity set Function

    clickEvent(e, id){
      this.useSetFeatureActive(e);
      this.setAmenityIndex(id);
    }
  },

  mounted(){
    this.setActiveOnMount()
    this.setFurnishedOnMount()
  },

  watch: {
    area: function() {
      this.useSetArea();
    },
  },

  components: {},
  props: [
    "guestsAllowed",
    "addGuestsAllowed",
    "subtractGuestsAllowed",
    "rooms",
    "addRooms",
    "subtractRooms",
    "beds",
    "addBeds",
    "subtractBeds",
    "baths",
    "addBaths",
    "subtractBaths",
    "balconies",
    "addBalconies",
    "subtractBalconies",
    "area",
    "setArea",
    "furnished",
    "setFurnished",
    "ams",
    "setAmenityIndex",
    "setListingTypeSingle",
    "setListingTypeMulti",
    "setIsPrivate",
    "isPrivate",
    "listingType",
    "usedAms"
  ],
};
</script>
<style lang="scss" scoped>
.custom-border-one {
  border-right: 1px solid rgba($color: #000000, $alpha: 0.1);
}
.custom-input-group {
  .custom-input-pair {
    .custom-input-large {
      width: 17rem;
    }
    .custom-input-small {
      width: 7rem;
    }
    label {
      font-weight: 600;
      color: rgba($color: #000000, $alpha: 0.6);
    }
    input {
      padding: 0 0.5rem;
      border-radius: 0.3rem;
      border: 1.3px solid rgba($color: #000000, $alpha: 0.2);
      height: 2.3rem;
      background-color: transparent;
      transition: 0.3s ease;
      outline: none;
      &::placeholder {
        color: rgba($color: #000000, $alpha: 0.5);
      }
      &:focus {
        border: 1.3px solid rgba($color: #0d6efd, $alpha: 0.35);
        box-shadow: 0px 3px 10px -2px rgba($color: #0d6efd, $alpha: 0.15);
      }
      &:active {
        border: 1.3px solid rgba($color: #0d6efd, $alpha: 0.35);
        box-shadow: 0px 3px 10px -2px rgba($color: #0d6efd, $alpha: 0.15);
      }
    }
  }
  .custom-radio-group {
    input {
      height: 1.3rem;
      width: 1.3rem;
      margin-right: 0.3rem;
    }
    label {
      line-height: 0;
    }
  }
}
.select-holder{
  display: flex;
  margin: 2rem 0;
  width: 80%;
}
.custom-select-card {
  height: 4rem;
  width: 100%;
  border-radius: 0.5rem;
  padding: 0rem 1rem;
  border: 1.3px solid rgba($color: #000000, $alpha: 0.1);
  position: relative;
  margin-right: 2rem;
  img {
    opacity: 0.8;
    height: 35%;
  }

  &.active{
    border: 1px solid rgba($color: #0d6efd, $alpha: 1);
  }
}
.custom-button-container {
  .custom-blue-button {
    width: 18px;
    height: 18px;
    border-radius: 3px;
    z-index: 3;
    cursor: pointer;
    &:nth-child(1) {
      margin-right: -5px;
    }
    &.custom-left {
      margin-left: -5px;
    }
    .custom-plus-sign {
      .line {
        width: 10px;
        height: 1.5px;
        background-color: white;
        &:nth-child(1) {
          transform: rotate(90deg);
        }
        &:nth-child(2) {
          transform: translateY(-1.3px);
        }
      }
    }
    .custom-minus-sign {
      .line {
        width: 10px;
        height: 1.5px;
        background-color: white;
      }
    }
    &:hover {
      opacity: 0.7;
      box-shadow: 0px 2px 20px -10px rgba($color: #0d6efd, $alpha: 0.7);
    }
  }
  input {
    width: 4rem;
    height: 1.7rem;
    background-color: #eee;
    border-radius: 5px;
    border: none;
  }
}
.custom-input-area {
  input {
    height: 2.5rem;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    outline: none;
    transition: 0.3s ease;
    padding: 0 0.5rem;
    &::placeholder {
      color: rgba($color: #000000, $alpha: 0.5);
    }
    &:focus {
      border: 1.3px solid rgba($color: #0d6efd, $alpha: 0.35);
      box-shadow: 0px 3px 10px -2px rgba($color: #0d6efd, $alpha: 0.15);
    }
    &:active {
      border: 1.3px solid rgba($color: #0d6efd, $alpha: 0.35);
      box-shadow: 0px 3px 10px -2px rgba($color: #0d6efd, $alpha: 0.15);
    }
  }
}
.custom-quality {
  padding: 0.7rem 2rem;
  border: 1px solid rgba($color: #000, $alpha: 0.35);
  border-radius: 10rem;
  cursor: pointer;
  &.active {
    border: 1.3px solid rgba($color: #0d6efd, $alpha: 0.65);
    box-shadow: 0px 3px 10px -2px rgba($color: #0d6efd, $alpha: 0.15);
    color: #0d6efd;
  }
}
.custom-box-feature {
  min-width: 12rem;
  height: 3rem;
  border-radius: 7px;
  box-shadow: 0px 10px 20px -5px rgba($color: #000000, $alpha: 0.09);
  cursor: pointer;
  transition: 0.3s ease;
  margin-right: 1rem;

  &.active {
    border: 1px solid rgba($color: #000000, $alpha: 0.7);
  }
  img {
    height: 1.7rem;
    width: auto;
    pointer-events: none;
  }
  div {
    pointer-events: none;
  }
}

@media only screen and (max-width: 550px) {
  .custom-container {
    flex-direction: column;
  }
  .custom-border-one {
    border: none;
  }
  .custom-subcontainer {
    width: 100% !important;
    &:nth-child(1) {
      padding-right: 0rem !important;
    }
    &:nth-child(2) {
      padding-left: 0rem !important;
    }
  }
  .custom-quality-wrapper {
    flex-direction: column;
  }
  .custom-quality {
    margin-bottom: 0.5rem;
    width: 100%;
    text-align: center;
  }
  .custom-box-feature {
    min-width: 10rem;
    font-size: 0.7rem !important;
  }
}
</style>
