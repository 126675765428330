<template>
  <div>
    <div class="w-100 d-flex justify-content-between align-items-center">
      <div
        class="custom-wrapper-div "
        v-for="(item, index) in steps"
        :key="index"
      >
        <div class="custom-wrapper-child">
          <div
            class="custom-container-div d-flex justify-content-center align-items-center"
          >
            <div
              class="custom-line"
              v-if="index != 0"
              :class="index == current ? 'bg-color-blue' : ''"
            ></div>
            <div
              class="custom-line-50"
              v-if="index == 0"
              :class="index == current ? 'bg-color-blue' : ''"
            ></div>
            <div v-if="index >= current - 1">
              <div
                class="custom-circle-outer d-flex justify-content-center align-items-center"
                :class="index == current - 1 ? 'border-blue' : ''"
              >
                <div
                  class="custom-circle-inner"
                  :class="index == current - 1 ? 'bg-color-blue' : ''"
                ></div>
              </div>
            </div>
            <div v-else>
              <div
                class="custom-circle-outer d-flex justify-content-center align-items-center border-green"
              >
                <div class="custom-circle-inner bg-color-green"></div>
              </div>
            </div>
            <div
              class="custom-line-50"
              v-if="index == total - 1"
              :class="index == current - 1 ? 'bg-color-blue' : ''"
            ></div>
            <div
              class="custom-line"
              v-if="index != total - 1"
              :class="index == current - 1 ? 'bg-color-blue' : ''"
            ></div>
          </div>
          <div class="d-flex flex-column mt-1">
            <div
              class="custom-media-class text-center font-small"
              :class="index == current - 1 ? 'color-black' : ''"
            >
              Step 0{{ index + 1 }}
            </div>
            <div
              class="custom-media-class-big text-center font-weight-700 font-mid-small"
              :class="index == current - 1 ? 'color-black' : ''"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: 5,

      steps: [
        "Basic Details",
        "Property Profile",
        "Photos",
        "Pricing & Others",
        "Misc. Amenities",
      ],
    };
  },
  props: ["current"],
};
</script>
<style lang="scss" scoped>
.custom-wrapper-div {
  width: 20%;
}
.border-blue {
  border-color: #0d6efd !important;
}
.custom-circle-outer {
  height: 1.24rem;
  width: 1.24rem;
  background-color: transparent;
  border: 1px solid rgba($color: #000000, $alpha: 0.2);
  border-radius: 50%;

  .custom-circle-inner {
    height: 0.9rem;
    width: 0.9rem;
    background-color: rgba($color: #000000, $alpha: 0.2);
    border-radius: 50%;
  }
}
.custom-line {
  width: 100%;
  height: 1px;
  background-color: rgba($color: #000000, $alpha: 0.2);
}
.custom-line-50 {
  width: 100%;
}
.border-green {
  border-color: #28c76f !important;
}

@media only screen and (max-width: 550px) {
  .custom-media-class {
    font-size: 0.8rem !important;
    font-weight: bold;
  }
  .custom-media-class-big {
    display: none;
  }
}
</style>
