<template>
    <div class="custom-navbar">
        <div class="custom-logo d-flex align-items-center">
            <MyLogo />
        </div>
        <button class="custom-back-to-home" @click="backToHome">
            Back to home page
        </button>
    </div>
</template>
<script>
import MyLogo from '@/views/newcomponents/Logo/Logo.vue';
export default {
    methods: {
        backToHome() {
            this.$router.push({path:'/pages/userdashboard'});
        }
    },
    components: {
        MyLogo
    }
}
</script>
<style lang="scss" scoped>
    .custom-navbar{
        width: 100%;
        height: 70px;
        padding: 0 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        font-family: "Poppins", sans-serif;
        z-index: 10000;
        background: #fff;
    }
    .square{
        height: 3rem;
        width: 3rem;
        background-color: #0D6EFD;
    }
    .custom-back-to-home{
        padding: 0.5rem 1rem;
        border: 1px solid red;
        border-radius: 5px;
        font-size: 12px;
        color: red;
        cursor: pointer !important; 
        background: transparent;
        transition: .3s ease;
        
        &:hover{
            color: #fff;
            background-color: red;
        }
    }
</style>